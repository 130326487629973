@import "../assets/variables.module.scss";

.zol-btn-home{
    display: block;
    max-width: 800px;
    margin: -20px auto 20px;
    color: $color-primary;
    text-decoration: underline;
    cursor: pointer;
    > svg{
        fill: $color-primary;
        width: 20px;
        height: 20px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
    }

    @media screen and (max-width: 980px) {
        margin: 0px 0px 0px 10px;
    }
}

.zol-page-saved{
    max-width: 800px;
    background-color: white;
    border-radius: 5px 5px 0px 0px;
    margin: 0 auto;
    padding-bottom: 40px;

    > h1{
        background-color: $color-primary;
        text-align: center;
        font-size: 18px;
        font-weight: normal;
        line-height: 40px;
        color: white;
        border-radius: 5px 5px 0px 0px;
    }

    > p{
        padding: 30px;
    }

    .saved-indicator{
        margin: 0px 30px;
        padding: 40px 0px 20px;
        border-top: 1px solid $color-off-white;
        &:nth-child(2){
          border-top: 0;
        }

        .indicator-path{
            background-color: $color-background;
            padding: 5px;

            .indicator-path-toggle{
                position: relative;
                &:before{
                    position: absolute;
                    right: 0px;
                    top: 3px;
                }
            }

            .indicator-path-item > svg{
                fill: $color-light-blue;
            }
        }


        .toggle-box-bullit-point{
            float: left;
            margin-top: 10px;
            &.core-kpi > svg:nth-child(2){
                display: block;
                width: 34px;
                height: 34px;
            }
        }
        .toggle-box-title{
            display: block;
            padding: 10px 0px 10px 40px;

            strong.core-kpi{
                display: block;
                padding-bottom: 5px;
                border-bottom: 2px solid $color-yellow;
                font-size: 120%;
            }

        }
    }

    .zol-srf-export{
        float: right;
        margin-right: 30px;
    }

    &:after{
        content: '';
        display: block;
        clear: right;
    }

    @media screen and (max-width: 980px) {
        .saved-indicator{
            margin: 0px 10px;
        }
    }
}