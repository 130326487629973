@import "../../assets/variables.module.scss";

.zol-form-element{

    .zol-form-element-input{
        width: 100%;
        display: flex;
        background-color: $color-white;
        align-items: flex-start;
        justify-content: center;
        margin: 10px 0;

        .zol-single-item-container{
            display: flex;
            flex-direction: column;
            width: 130px;
            // Input element style
            .zol-single-input{
                outline: none;
                margin-bottom: 5px;
                border: none;
                padding: 10px;
                background-color: $color-off-white;
                height: 20px;
                border: 1px solid $color-light;
            }

            // select element style
            .zol-single-select{
                outline: none;
                border: none;
                padding: 10px;
                background-color: $color-off-white;
                border: 1px solid $color-light;
                //margin: 10px 0px 5px 0px;
            }
            .zol-single-radio{
                display: flex;
                justify-content: space-around;
                label{
                    input{
                        position:absolute;
                        top:-20px;
                    }
                }
            }
            .zol-single-error{
                border: 2px solid $color-red
            }
            

            .zol-single-checkbox-item{
                margin: 10px 0px;
            }
        }
    }

    .zol-form-element-error-text{
        font-size: 12px;
        font-family: 'Lato', sans-serif;
        color: $color-red;
    }

    .zol-form-element-title{
        width: calc(100% - 130px);
        font-family: 'Lato', sans-serif;
        font-size: 14px;
        font-weight: bold;
        color: $color-dark;
        margin: 10px 0px 5px 0px;
    }

    .zol-form-element-wide {
        flex-direction: column;
        .zol-form-element-title{
            width: 100%;
            color: $color-medium;
            font-size: 14px;

        }
        .zol-single-item-container{
            width: 100%;
        }
    }

    .zol-form-element-description{
        font-style: italic;
        font-size: 110%;
        color: $color-light;
    }
}