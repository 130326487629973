@import "../../../assets/variables.module.scss";

.zol-ui-switch, .zol-ui-switch-vertical{
    position: relative;
    display: inline-block;
    background-color: $color-off-white;
    border-radius: 10px;
    overflow: hidden;

    .zol-ui-switch-highlight{
        position: absolute;
        // z-index: 1;
        background-color: $color-primary;
        transition: left 0.3s
    }

    .zol-ui-switch-item{
        position: relative;
        // z-index: 2;
        display: inline-block;
        vertical-align: middle;
        box-sizing: border-box;
        min-width: 20px;
        padding: 10px 20px;
        text-align: center;
        color: $color-dark;
        cursor: pointer;
        transition: color 0.3s;

        &.zol-active{
            color: $color-white;
            cursor: default;
        }
    }
}