.zol-select-grouped{
    position: relative;
    display: inline-block;
    padding: 5px 5px 5px 10px;
    background-color: rgba(0, 0, 0, 0.1);
    width: 250px;

    .zol-select-grouped-label{
        width: calc(100% - 30px);
        height: 1.5em;
        vertical-align: middle;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: default;
    }

    .zol-select-grouped-toggle{
        vertical-align: middle;
        display: inline-block;
        width: 20px;
        height: 14px;
        margin-left: 10px;
        background: transparent url('../../../assets/icons/fa/angle-down.svg') center center no-repeat;
        background-size: 14px;
        transition: transform 0.3s;
    }

    &.zol-has-value .zol-select-grouped-toggle{
        background-image: url('../../../assets//icons/fa/close.svg');
    }

    &.zol-active .zol-select-grouped-toggle{
        background-image: url('../../../assets/icons/fa/angle-down.svg');
        transform: scale(-1);
    }
}

.zol-select-grouped-dropdown{
    position: absolute;
    top: 100%;
    left: 0px;
    width: 100%;
}

.zol-select-grouped-dropdown, .zol-select-grouped-modal{    

    > .zol-select-grouped-search{
        display: block;
        position: absolute;
        top: -30px;
        height: 30px;
        left: 0px;
        right: 40px;
        > input{
            width: 100%;
            border: 0px;
            outline: none;
            padding: 5px;
        }
    }

    > ul{
        position: absolute;
        max-height: 70vh;
        min-width: 100%;
        overflow-y: auto;
    }

    ul.zol-select-grouped-options{
        background-color: white;
        color: black;
        margin: 0px;
        padding: 0px;
        list-style: none;
        border-top: 1px solid #eee;

        &.zol-level-0{
            border-top: 0px;
        }
    }

    li.zol-select-grouped-option{
        padding: 5px 0px;
        border-top: 1px solid #eee;

        &:first-child{
            border-top: 0px;
        }

        .zol-select-grouped-checkbox{
            float: left;
            width: 14px;
            height: 14px;
            border: 1px solid #aaa;
            background-color: white;
            margin: 3px 5px 0px 5px;
            vertical-align: top;
            position: relative;
        
            &.zol-checkbox-checked{
                background-image: url('../../../assets/icons/check.svg');
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 12px;
            }

            &.zol-checkbox-partly:before{
                content: '';
                display: block;
                position: absolute;
                top: 4px;
                bottom: 4px;
                left: 4px;
                right: 4px;
                background-color: #888;
                border-radius: 2px;
            }
        }
        .zol-level-1 .zol-select-grouped-checkbox{
            margin-left: 15px;
        }
        .zol-level-2 .zol-select-grouped-checkbox{
            margin-left: 25px;
        }

        .zol-select-grouped-option-label{
            display: block;
            padding-left: 26px;
            cursor: default;
        }
        .zol-level-1 .zol-select-grouped-option-label{
            padding-left: 36px;
            font-size: 80%;
        }
        .zol-level-2 .zol-select-grouped-option-label{
            padding-left: 46px;
            font-size: 70%;
        }

        .zol-select-grouped-group-toggle{
            float: right;
            width: 16px;
            height: 16px;
            border: 1px solid #aaa;
            border-radius: 50%;
            line-height: 16px;
            font-size: 16px;
            font-weight: bold;
            text-align: center;
            margin-right: 5px;
            cursor: pointer;
            text-indent: 40px;
            overflow: hidden;
            background: transparent url('../../../assets/icons/fa/plus.svg') center center no-repeat;
            background-size: 10px;

            &.zol-active{
                background-image: url('../../../assets/icons/fa/minus.svg');
                background-size: 10px;
            }
        }
        
    }
}

.zol-modal-overlay .zol-modal.zol-select-grouped{
    padding: 0px;

    .zol-modal-header{
        padding: 20px 10px 0px;
    }

    .zol-modal-content{
        padding: 0px;
    }

    .zol-select-grouped-search{
        position: static;
        margin: 10px 5px;

        input{
            background: white;
            width: calc(100% - 12px);
            color: black;
            border: 1px solid #ddd;

            &::placeholder{
                color: rgba(0, 0, 0, 0.5);
            }
        }
        
    }

    ul{
        position: static;
        width: 100%;

        li{
            padding: 15px 0px;

            &.zol-select-grouped-option .zol-select-grouped-group-toggle{
                margin-right: 15px;
                height: 24px;
                width: 24px;
                font-size: 20px;
                line-height: 24px;
            }

            &.zol-select-grouped-option .zol-select-grouped-checkbox{
                margin: 3px 8px 0px 15px;
                width: 20px;
                height: 20px;
            }
        }
    }
}

.select-grouped-modal {
    position: relative;
}