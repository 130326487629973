@import '../../assets/variables.module.scss';

.pdf-content{

    > div{
        width: 1400px;
        margin: 0 auto;

        &.srf-donut{
            width: 1000px;

            &.is-selected{
                display: none;
            }
        }

        .zol-long-term-details, .zol-priority-details{
            margin: 0px auto;
        }
    }

    .zol-page-saved{
        max-width: 1200px;

        .indicator-status{
            display: none;
        }
    }

    .zol-oee-indicators, .zol-ccp-indicators{
        div.block-text{
            border: 1px solid $color-blue-text;
        }
    }

}

.export-button-container{
    display: inline-block;
    position: relative;

    > div{
        position: absolute;
        bottom: calc(100% + 10px);
        right: 0px;
        width: 260px;
        background-color: $color-primary;
        border-radius: 7px 7px 0px 0px;
    
        >span{
            display: block;
            padding: 5px 10px;
            border-bottom: 1px solid rgba(255,255,255,0.5);
            color: white;
            text-align: left;
            font-size: 80%;
            cursor: pointer;

            &:hover{
                background-color: rgba(255,255,255,0.2);
            }

            &.export-pdf{
                font-size: 110%;
                padding-left: 30px;
                background: transparent url('../../assets/icons/download.svg') 5px center no-repeat;
                background-size: 20px;
            }
        }
    }

    &.zol-active .zol-ui-button:after{
        content: '';
        display: block;
        position: absolute;
        top: -10px;
        left: 0px;
        width: 100%;
        height: 16px;
        background-color: $color-primary;
    }
}