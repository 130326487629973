@import "../../assets/variables.module";

.zol-oee-indicators, .zol-ccp-outcomes {
    .zol-item-component{
        z-index: none;
        max-width: 600px;

        .zol-item-block{
            margin-bottom: 40px;
        }

        .zol-item-block .block-title{
          float: none;
          display: block;
        }
        .zol-item-block .block-text{
            padding: 10px 30px 30px;
            text-align: left;
            width: auto;
            font-size: 90%;
            float: none;

            p.indicator-text-main{
                font-size: 110%;
            }

            p {
                strong {
                    display: inline-block;
                    min-width: 30px;
                    vertical-align: top;
                }
                span {
                    display: inline-block;
                    width: calc(100% - 40px);
                }
            }
        } 
    }

    > div:last-child{
        .zol-item-component .zol-item-block{
            margin-bottom: 0;
        }
    }
}
