/* Global project style file. Here we can define some general stuff */
@import "./assets/variables.module.scss"; 
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');

body {
    margin: 0;
    font-family: "Open Sans", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.zol-text-uppercase{
    text-transform: uppercase;
}

.zol-link{
    color: $color-blue-text;
    cursor: pointer;
    text-decoration: underline;
    pointer-events: auto;
}

.page-break{
    page-break-after: avoid;
    page-break-inside: avoid;
}

h2.zol-error{
    color: $color-red;
}
h2.zol-success{
    color: $color-green;
}

.zol-full-layout-buttons{
    position: fixed;
    bottom: 20px;
    right: 20px;
}

.admin-indicator-guidance-field{
    margin-bottom: 20px;
    label{
        display: block;
    }

    textarea{
        width: 100%;
        min-height: 200px;
    }
}