@import '../../assets/variables.module.scss';

.zol-feedback-container{
  z-index: 5;
  position: fixed;
  bottom: 0;
  left: 30px;
  transition: opacity 0.5s;
  
  .zol-feedback{
    .zol-feedback-button{
      display: flex;
      align-items: center;
      cursor: pointer;
      width: 100px;
      justify-content: space-between;
      background-color: $color-blue;
      color: #fff;
      padding: 6px 15px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      .feedback-icon{
        margin-left: 8px;
      }
    }
    .zol-feedback-content{
      border-top-right-radius: 10px;
      height: 0;
      max-height: 0px;
      padding: 0;
      border-top: 2px solid $color-blue;
      border-left: 2px solid $color-blue;
      border-right: 2px solid $color-blue;
      margin-bottom: -2px;
      background-color: #fff;
      max-width: 400px;
      margin-right: 5px;
      transition: max-height 0.5s;
    }
  }
  .zol-feedback-open{
    .zol-feedback-content{
      height: auto;
      max-height: 400px;
      padding: 15px;
    }
  }
  @media screen and (max-width: 980px){
    left: 5px;
  }
}