@import '../../assets/variables.module.scss';

#zol-header {
  position: fixed;
  width: 100%;
  z-index: 3;
  color: #fff;

  @media screen and (max-width: 980px) {
      position: relative;
  }

  .search-container{
      display: block;
      position: relative;
      margin: 0px auto;
      width: 100%;
      max-width: 400px;

      @media screen and (max-width: 1200px) {
          float: right;
          margin-right: 20px;
          max-width: 300px;
      }

      @media screen and (max-width: 980px) {
          max-width: 100%;
      }
  }
  
  #zol-header-row-1 {
    position: relative;
    padding-top: 10px;
    height: 55px;
    background-color: #0033a0;
    text-align: center;

    .srf-logo{
        float: left;

        > svg{
          width: calc(100% - 20px);
          height: 45px;
          cursor: pointer;
        }
    }

    .header-buttons{
        float: right;
        line-height: 45px;
        padding-right: 15px;
    }

    @media screen and (max-width: 790px) {
        height: 82px;

        .header-buttons{
            float: none;
            clear: both;
            display: block;
            background-color: $color-light-blue;
            line-height: 30px;
            text-align: left;

            .srf-header-languages{
                float: right;
                margin-right: 10px;
            }

            .saved-items-button{
                margin-left: 10px;
            }
        }

        
    }

    .srf-header-languages{
      display: inline-block;
      vertical-align: middle;
      width: 60px;
      margin-right: 35px;
      font-size: 14px;

      .language-title{
        text-transform: uppercase;
        cursor: pointer;
        &:hover{
          color: rgba(255, 255, 255, .75);
        }
      }
      .language-separator{
        margin: 0 2px;
        user-select: none;
      }
      .language-separator, .language-title{
        color: rgba(255, 255, 255, .5);
      }
      .language-active{
        color: rgba(255, 255, 255, 1);
        font-weight: bold;
        border-bottom: 2px solid $color-yellow;
      }
    }

    .saved-items-button{
        position: relative;
        display: inline-block;
        vertical-align: middle;
        font-size: 90%;
        padding-left: 30px;
        margin-left: 20px;
        background: transparent url('../../assets/icons/saved-items.svg') left center no-repeat;
        background-size: 25px;
        text-decoration: underline;
        cursor: pointer;

        &.zol-active{
            background-image: url('../../assets/icons/saved-items-active.svg');
        }

        .saved-items-note{
            position: absolute;
            z-index: 1;
            top: 100%;
            right: 0px;
            width: 400px;
            padding: 30px;
            color: black;
            text-align: left;
            background-color: white;
            box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
            line-height: 1.5em;
            transition: opacity 1s;

            &:after{
                content: "";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                right: 50px;
                top: 0;
                margin-top: -25px;
                border: solid transparent;
                border-bottom-color: white;
                border-width: 15px;
            }

            h3{
                margin: 0px;
                font-weight: normal;
                font-size: 20px;
            }

            p{
                font-size: 110%;
                margin: 15px 0px;
            }
        }
    }

    .zol-select-dropdown-open{
      .zol-select-content{
        border-radius: 15px 15px 0 0;
      }
    }
  }

  #zol-header-row-2 {
    
    width: 100%;
    height: 46px;
    background-color: #4867b2;
    display: inline-block;
    @media screen and (max-width: 980px) {
      color: #000;
      height: 100%;
      background-color: #ebf3ff;
    }
    > span {
      vertical-align: middle;
      line-height: 45px;
      font-weight: 500;
      margin-left: 10px;
    }
    .row-2-search{
      padding: 10px;

      .search-container{
          display: inline-block;
          float: none;
          margin-right: 10px;
          width: calc(100% - 50px);
      }

      .filter-toggle{
          position: relative;
          display: inline-block;
          top: -5px;
          width: 32px;
          height: 32px;
          text-align: center;
          line-height: 32px;;
          border-radius: 50%;
          background-color: $color-primary;

          > svg{
              fill: white;
              width: 20px;
              height: 20px;
              display: inline-block;
              vertical-align: middle;
          }
          > em {
              position: absolute;
              top: -5px;
              right: -5px;
              width: 16px;
              height: 16px;
              background-color: $color-red;
              color: white;
              line-height: 16px;
              text-align: center;
              font-size: 14px;
              font-weight: bold;
              font-style: normal;
          }
          > strong{
              position: absolute;
              font-weight: normal;
              font-style: italic;
              opacity: 0.7;
              font-size: 11px;
              line-height: 11px;
              bottom: -13px;
              left: 0px;
              right: 0px;
              text-align: center;
          }
      }
    }

    .zol-header-filter-bar-mobile {
      padding: 10px;
      overflow: scroll;
      -webkit-box-shadow: 0 8px 10px -4px rgba(0, 0 ,0, .35);
      -moz-box-shadow: 0 8px 10px -4px rgba(0, 0 ,0, .35);
      box-shadow: 0 8px 10px -4px rgba(0, 0 ,0, .35);
      .header-filter-text {
        display: inline-block;
        width: 100%;
      }
      .tile-active{
        position: relative;
        &:after{
          content: '';
          position: absolute;
          left: -1px;
          bottom: -7px;
          width: calc(100%);
          background-color: #F3F8FF;
          height: 10px;
          border-right: solid 1px #808080;
          border-left: solid 1px #808080;
        }
      }
      .zol-select-content{
        border: 1px solid $color-medium;
        background-color: white;
        border-radius: 20px;
        padding: 0px 5px;
      }
      .zol-select-value-input, .zol-select-value-label, ::placeholder{
        color: $color-dark;
        font-style: italic;
      }
      .zol-select-suffix-default{
        color: $color-primary;
        font-size: 24px;
      }

      .reset-filter, .apply-filter{
        display: inline-block;
        text-align: center;
        cursor: pointer;
        background-color: $color-primary;
        padding: 5px 10px;
        margin-top: 5px;
        > span {
          font-style: normal;
          font-size: 16px;
          vertical-align: middle;
          color: white
        }
      }
    }

    .zol-header-filter-bar {
      align-items: center;
      float: right;
      height: 45px;
      background-color: rgb(72, 103, 178);

      .srf-header-find{
        margin-right: 3px;
        height: 45px;
        width: 250px;
        display: inline-block;
        .zol-tree{
          text-align: left;
        }
        @media screen and (max-width: 1024px) {
          width: 150px
        }
        @media screen and (max-width: 980px) {
          padding: 10px;
          width: 250px;
        }
        .zol-select-content{
          border: none;
          background-color: #5b77ba;
        }
        .zol-select-value-input, .zol-select-value-label, ::placeholder{
          color: $color-white !important;
        }
        .zol-tree-node-active{
          color: #fff;
          background-color: #cdf;
        }
        .zol-header-filter-sdg-icon, .zol-header-filter-gcm-icon{
          background-color: #e4eaf6;
          position:relative;
          width: 250px;
          border-radius: 0 0 10px 10px;
          height: 70px;
          z-index: -1;
          transition: top 700ms;
          text-align: center;
          @media screen and (max-width: 1024px) {
            width: 150px
          }
          .gcm-icon{
            margin: auto;
            max-height: 50px;
            max-width: 200px;
            padding: 10px;
            @media screen and (max-width: 1024px){
              width: 80%;
            }
          }
          .sdg-icon{
            margin: auto;
            max-height: 50px;
            max-width: 200px;
            padding: 10px;
            @media screen and (max-width: 1024px){
              width: 80%;
            }
          }
        }
      }
      .header-filter-text {
        padding-right: 10px;
        vertical-align: middle;
        display: inline-block;
        font-style: italic;
        @media screen and (max-width: 980px) {
          width: 100%;
        }
      }
      .reset-filter, .apply-filter{
          display: inline-block;
          text-align: center;
          text-transform: uppercase;
          cursor: pointer;
          background-color: #5b77ba;
          width: 45px;
          height: 45px;
          margin: 0 2px;
          padding: 0px 10px;
          @media screen and (max-width: 980px) {
            width: auto;
            padding: 0px 30px;
          }
        
          font-size: 14px;
          line-height: 45px;
          color: white;

          &:hover{
            @media screen and (max-width: 980px) {
              background-color: #5b77ba !important;
            }
            background-color: rgba(255,255,255, .5) !important;

          }
      }
      .zol-select-dropdown-default .zol-select-dropdown-item{
        align-items: start;
      }
    }
  }

  #zol-header-filter:after{
      content: '';
      display: block;
      clear: right;
  }

  .header-row-mobile {
    height: auto !important;
  }
}

.mobile-filter-block{
  border: 1px solid grey;
  // dont adjust border width or margin or padding without adjusting
  // the calculated width in render_dropdown_tiles.js
  border-radius: 10px;
  margin: 5px;
  vertical-align: middle;
  padding: 5px;
  display: inline-flex;
  flex-direction: column;
  .filter-block-checkbox{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: inline-block;
    padding: 5px;
    line-height: 20px;
    text-align: center;
    color: white;
    font-size: 14px;
    font-weight: bold;
    border: 1px solid grey;
    margin-right: 15px;
    >svg{
      width: 100%;
      height: 100%;
      fill: white;
    }
  }
  >p{
    margin: 5px 0;
  }
  .filter-block-child{
    margin: 10px 0;
    font-weight: normal;
  }
}
.filter-block-button{
  background-color: $color-primary;
  color: white;
  border-radius: 15px;
  width: fit-content;
  padding: 5px 15px;
  margin: 0 auto;
  font-size: 14px;
  display: table-cell;
}