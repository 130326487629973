@import "../../assets/variables.module.scss";

.indicator-status{
    margin-top: 20px;
    font-size: 90%;

    @media screen and (max-width: 980px) {
        font-size: 75%;
    }

    svg{
        display: inline-block;
        width: 20px;
        height: 20px;
        vertical-align: middle;
    }

    .indicator-open-details{
        color: $color-primary;
        text-decoration: underline;
        cursor: pointer;
        > svg{
            margin-right: 5px;
            fill: $color-primary;
        }
    }

    .indicator-saved, .indicator-remove{
        float: right;
        color: $color-medium;
        font-style: italic;
        cursor: pointer;
        > svg{
            margin-left: 5px;
            fill: $color-medium;
        }
        &.zol-active > svg{
            fill: $color-yellow;
        }
    }

    .indicator-remove{
        color: $color-primary;
        > svg{
            fill: $color-primary;
        }
    }

}