@import "../../assets/variables.module";
.zol-long-term-details{
  z-index: 1;
  position: relative;
  height: 250px;
  max-width: 550px;
  margin: -300px auto 0;
  .donut-annotation-sdgs{
    height: auto;
    display: block;
    position: static;
    width: 100%;
    svg{
      width: 30px !important;
    }
  }
  @media screen and (max-width: 980px){
    max-width: 100vw;
    background-color: #fff;
    padding: 10px 10px 20px;
    margin-top: -300px;
    height: 200px;
  }
  @media screen and (max-width: 690px){
    margin-top: -300px;
    height: auto;
  }
  @media screen and (max-width: 600px){
    margin-top: -330px;
  }
  @media screen and (max-width: 576px){
    margin-top: -160px;
  }
  @media screen and (max-width: 420px){
    margin-top: -110px;
  }
}

.zol-short-term-overview-title{
  cursor: pointer;
  text-decoration: underline;
  color: $color-blue-text;
  font-size: 14px;
}

.zol-short-term-overview{
  width: 100%;
  background-color: $color-background2;
  display: inline-block;
  font-size: 14px;
  .zol-short-term-overview-block{
    padding-top: 10px;
    border-top: 1px solid $color-off-white
  }
  .-inactive{
    text-decoration: none;
    color: $color-light;
  }
  .zol-short-term-overview-items{
    width: 100%;
    color: black;
    margin-top: 5px;
    padding: 5px 0;
    span{
      display: inline-block;
      margin: 5px;
    }
    p{
      color: $color-light;
    }
    .zol-st-overview-output{
      // text-transform: uppercase;
      cursor: pointer;
      width: calc(30% - 16px);
      font-weight: bold;
      color: #fff;
      padding: 8px 0;
    }
    .zol-st-overview-rp{
      cursor: pointer;
      display: inline-block;
      .zol-st-overview-rp-box{
        width: 40px;
        height: 40px;
        background-color: #4867B2;
        margin: 5px;
        color: #fff;
        display: inline-block;
        line-height: 40px;
      }
    }
    .zol-st-overview-gcm{
      cursor: pointer;
      display: inline-block;
      .icon{
        width: 40px;
        display: inline-block;
      }
    }
    .zol-st-overview-sdg{
      cursor: pointer;
      display: inline-block;
      span{
        width: 40px;
        display: inline-block;
      }
    }
  }
}