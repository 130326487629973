@import "../../assets/variables.module";
.zol-priority-details{
  position: relative;
  height: 257px;
  max-width: 550px;
  margin: -300px auto 0;
  h2{
    font-size: 18px;
  }
  h3{
    margin: 0px;
    font-size: 14px;
  }
  @media screen and (max-width: 980px){
    max-width: 100vw;
    background-color: #fff;
    height: auto;
    padding: 10px 10px 20px;
  }

  @media screen and (max-width: 980px){
    margin-top: -300px;
    height: 200px;
  }
  @media screen and (max-width: 690px){
    margin-top: -300px;
    height: auto;
  }
  @media screen and (max-width: 600px){
    margin-top: -330px;
  }
  @media screen and (max-width: 576px){
    margin-top: -160px;
  }
  @media screen and (max-width: 420px){
    margin-top: -130px;
  }
}

.zol-ccp-outcomes {
    .zol-item-component{
        z-index: none;
        .zol-item-block .block-title{
          float: none;
          display: block;
        }
        .zol-item-block .block-text{
            padding: 10px 30px 30px;
            text-align: left;
            width: auto;
            font-size: 90%;
            float: none;
        } 
    }
}

.zol-ccp-output{
  // width: calc(100% - 20px);
  background-color: $color-background2;
  // display: inline-block;
  font-size: 14px;
  text-align: left;
  padding: 20px 30px;

  @media screen and (max-width: 980px) {
      margin-bottom: 40px;
  }
  

  .zol-ccp-output-indicators{
    background-color: white;
    border-radius: 10px;
    margin: 10px -10px;
    .indicator-box-item{
      padding: 10px;
      border-bottom: 2px solid $color-off-white;
      display: inline-flex;
      &:last-child{
        border-bottom: none;
      }
      .bullit-point{
        display: inline-block;
        width: 20px;
        svg{
          width: 30px;
          height: 26px;
          fill: $color-light-blue;
        }
      }
      .ccp-indicator-title{
        display: inline-block;
        padding: 0px 15px;
      }
    }
  }
}

.zol-ccp-outcomes > div:last-child{
  .zol-ccp-output{
      margin-bottom: 0;
  }
}