@import '../../assets/variables.module.scss';

.search-dropdown{
  border-radius: 15px 0 15px 15px;
  position: absolute;
  background-color: #F3F8FF;
  text-align: left;
  color: #000;
  top: 38px;
  overflow: auto;
  padding: 0px 15px 15px;
  right: -2px;
  z-index: 10;
  box-shadow: 1px 1px 3px #818793;
  border: 1px solid #818793;
  border-top-width: 0px;
  width: 550px;
  @media screen and (max-width: 980px) {
    top: 41px;
    width: calc(100% + 5px);
    right: auto;
    border-radius: 0 15px 15px 15px;
  }
  .category-title{
    margin: 5px 0px 10px;
    font-weight: bold;
  }
  .category-bar{
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 5px;
    &::-webkit-scrollbar{
      height: 8px;
      background-color: $color-off-white;
    }
    &::-webkit-scrollbar-thumb{
      background-color: $color-light;
      border-radius: 10px;
    }
    .category-topic{
      cursor: pointer;
      display: inline-block;
      margin: 0px 5px;
      text-align: center;
      font-size: 12px;
      &:hover{
        .category-bubble, p{
          opacity: .6;
        }
      }
      .category-bubble{
        height: 25px;
        width: 25px;
        border-radius: 50%;
        display: inline-block;
        padding: 5px;
        line-height: 25px;
        text-align: center;
        color: white;
        font-size: 14px;
        font-weight: bold;
      }
      p{
        margin-bottom: 0;
      }
    }
  }
  .group-title{
    padding: 5px 4px 4px;
    font-size: 16px;
    margin: 15px 0 5px;
    position: sticky;
    top: 0;
    background: $color-background;
    font-weight: bold;
  }
  .group-list{
    list-style: none;
    padding: 0;
    margin: 0;
    .group-list-item{
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      padding: 10px;
      color: #000;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-top: 8px;
      &:hover{
        color: #fff !important;
        background-color: #0033a0;
        border-left-color: #0033a0 !important;
        .group-icon{
          color: #fff !important;
        }
      }
    }
    .group-list-item-empty{
      cursor: default;
    }
  }
}