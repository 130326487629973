@import "../../assets/variables.module";

.zol-details-short-term-navigation{
  display: inline-block;
  width: 100%;
  max-width: 800px;
  position: relative;
  height: 100px;
  text-transform: uppercase;
  .zol-navigation-arrow-active{
    width: 100px;
    font-weight: bold;
    cursor: pointer;
    .zol-arrow{
      font-weight: 400;
      font-size: 24px;
      background-color: $color-blue-text;
      color: white;
      width: 30px;
      height: 30px;
      line-height: 26px;
      display: inline-block;
      border-radius: 50px;
    }
    p {
      font-weight: bold;
      margin: 5px 0 0;
      font-size: 14px;
      color: $color-blue-text;
    }
  }

  .zol-navigation-arrow-inactive{
    width: 100px;
    font-weight: bold;
    .zol-arrow{
      font-weight: 400;
      font-size: 24px;
      background-color: $color-light;
      color: white;
      width: 30px;
      line-height: 26px;
      height: 30px;
      display: inline-block;
      border-radius: 50px;
    }
  }

  .zol-navigation-arrow-left{
    position: absolute;
    top: 75px;
    left: 0;
  }

  .zol-navigation-arrow-right{
    position: absolute;
    top: 75px;
    right: 0;
  }
  .zol-navigation-arrow-up{
    position: absolute;
    left: calc(50% - 15px);
    top: 30px; // circle in middle of line
    width: 150px;
    z-index: 1;
    cursor: pointer;
    .zol-arrow-up{
      margin-top: 15px;
      top: 50%;
      float: left;
    }
    >p{
      margin: 0 0 0 5px;
      width: calc(100% - 35px);
      float: left;
      top: 50%;
    }
  }
}

.details-short-term{
  position: relative;
  margin: 0px auto;
  max-width: 800px;

  .block-text{
    font-size: 120%;
  }

  &:before {
    content:"";
    margin: 0 auto; /* this centers the line to the full width specified */
    position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
    top: 0; left: 50%; right: 0;
  }

  .details-short-term-content{
    //display: inline-block;
    padding: 0 20px 20px;
    background-color: #fff;
    width: calc(100% - 40px);

    .zol-button-switch{
      width: 100%;
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      justify-content: center;
      span{
        /* somewhere there is this pixel extra width added to the item. Not sure, there doesn't seem to be
            a border and we have border-box sizing enabled */
        width: calc(20% - 1px);
        min-height: 65px;
      }
    }
  }
}
.short-term-content-output{
  border: 1px solid #2582df;
  margin: 40px 0;
  text-align: left;
  .output-num{
    display: inline-flex;
    padding: 10px;
    margin-right: 10px;
    background-color: #2582df;
    color: #fff;
    font-weight: bold;
  }
  .output-title{
    margin: 20px 10px;
  }
}
.zol-short-term-outputs, .st-mobile-details-list, .details-short-term-content{
  .zol-st-toggle{
    pointer-events: none;
    opacity: .7;
    text-decoration: none;
    margin: 0 5px;
  }
  .zol-st-toggle-active{
    pointer-events: initial;
    text-decoration: underline;
    cursor: pointer;
    opacity: 1;
    color: #0033A0
  }
}

.zol-show-toggle-container{
  width: calc(100% - 40px);
  text-align: left;
  background-color: $color-off-white;
  padding: 10px 20px;
  .zol-show-toggle{
    height: 30px;
    font-weight: bold;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    cursor: pointer;
    color: $color-blue-text;
    background-color: $color-off-white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .indicator-number{
      margin: 0;
    }
    .show-hide-button{
      margin: 0;
      svg{
        width: 20px;
        height: 20px;
        fill: $color-blue-text;
        position: relative;
        /* Adjust these values accordingly */
        top: 4px;
        left: -3px;
        transition-duration: 0.7s;
      }
    }
  }
}

.zol-show-toggle-box{
  cursor: default;
  background-color: white;
  margin: 10px 0 20px;

  .toggle-box-item{
    padding: 30px 0;
    margin: 0px 20px;
    border-bottom: 2px solid $color-off-white;
    // display: inline-flex;
    &:last-child{
      border-bottom: none;
    }
    .toggle-box-bullit-point{
      display: inline-block;
      width: 20px;
      margin-right: 15px;
      vertical-align: top;
      svg{
        width: 30px;
        height: 26px;
        fill: $color-light-blue;
      }

      &.core-kpi{
        svg:nth-child(2){
          width: 40px;
          height: 40px;
        }
      }
    }
    .toggle-box-title{
      display: inline-block;
      padding: 0px 20px;
      width: calc(100% - 76px);

      strong.core-kpi{
        display: block;
        padding-bottom: 5px;
        border-bottom: 2px solid $color-yellow;
        font-size: 120%;
      }
    }

    @media screen and (max-width: 980px) {
      margin: 0px 5px;
      .toggle-box-bullit-point{
        margin-right: 5px;
        > svg{
          width: 20px;
          height: 20px;
        }
        &.core-kpi > svg:nth-child(2){
          margin-left: -30px;
        }
      }
      .toggle-box-title{
        padding: 0px;
      }
    }
  }
}

.st-outcome-switch{
    .zol-ui-switch-item{
        width: 19%;
        vertical-align: middle;
    }
}