// Donut filter
.zol-introduction-step-0{
  #zol-header,
  .zol-feedback,
  .donut-annotation,
  .zol-details-page,
  .donut-lt-outcome,
  .donut-center,
  .zol-srf-export{
    pointer-events: none;
    filter: brightness(50%);
  }
  .zol-main{
    pointer-events: none;
    background-color: #72757b;
  }
  .gradient{
    display: none;
  }
}

.zol-introduction-step-1{
  #zol-header,
  .zol-feedback,
  .zol-st-outcomes,
  .donut-center,
  .zol-donut-segments,
  .zol-srf-export{
    pointer-events: none;
    filter: brightness(50%);
  }
  .zol-main{
    pointer-events: none;
    background-color: #72757b;
  }
  .gradient{
    display: none;
  }
}

.zol-introduction-step-2{
  #zol-header,
  .zol-feedback,
  .donut-lt-outcome,
  .donut-center,
  .zol-donut-segments,
  .zol-srf-export{
    pointer-events: none;
    //opacity: .25;
    filter: brightness(50%);
  }
  .zol-main{
    pointer-events: none;
    background-color: #72757b;
  }
  .gradient{
    display: none;
  }
}

// Filter css
.zol-introduction-step-3{
  #zol-header-row-1,
  .zol-header-filter-sdg-icon,
  .zol-header-filter-gcm-icon{
    pointer-events: none;
    filter: brightness(50%);
  }
  .zol-main{
    background-color: #72757b;
    filter: brightness(75%);
  }
  .gradient{
    display: none;
  }
}