@import '../../../assets/variables.module.scss';

.zol-tree{
  padding: 10px;
  list-style: none;
  margin: 0;
  ul{
    list-style: none;
    margin: 0;
    padding: 0 0 0 20px;
  }

  .icon{
    padding-right: 5px;
  }
  .tree-header-container{
    cursor: pointer;
  }
  .zol-tree-node-content{
    width: 100%;
    display: inline-block;
    cursor: pointer;
  }

  .zol-tree-node-child{
    padding: 5px 0;
    display: flex;
    justify-content: space-between;
    &:hover{
      color: $color-white;
      background-color: $color-light;
      cursor: pointer;
    }
  }
  .zol-tree-node-head{
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }
  .zol-tree-node{
    .zol-tree-node-toggle{
      float: right;
      color: $color-medium;
      cursor: pointer;
    }
  }
  .zol-tree-child{
    font-size: 12px;
    font-weight: normal;
    color: $color-dark-blue;
  }
  .zol-tree-parent{
    border-top: 1px solid rgb(203, 217, 226);
    font-size: 15px;
    color: $color-medium;
    font-weight: bold;
    &:last-child{
      border-bottom: 1px solid rgb(203, 217, 226);
    }
  }
  .zol-tree-node-disabled{
    color: $color-medium;
    &:hover{
      color: $color-medium;
      background-color: $color-white;
    }
    .tree-header-container{

      cursor: not-allowed !important;
    }
    cursor: not-allowed;
    opacity: .5;
  }
}
