@import '../../assets/variables.module.scss';

.zol-full-layout-modal .zol-modal{
    .zol-modal-content{
        overflow: auto;
    }
    .zol-modal-header{
        background-color: $color-light-blue;
        color: white;
        font-weight: bold;

        .close-icon{
            fill: white;
        }
    }

    .zol-full-layout-buttons{
        transition: opacity 0.5s;
        position: absolute;
        right: 20px;
        bottom: 20px;
        z-index: 5;
    }
}
