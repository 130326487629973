$color-white: #fff;
$color-off-white: #EBF0F7;
$color-light: #C0C3C9;
$color-medium: #818793;
$color-dark: #2E384D;

$color-primary: #0033A0;
$color-light-blue: #4867b2;
$color-blue: #0033A0;
$color-blue-text: #0033A0;
$color-dark-blue: #3b65c5;
$color-red: #F98282; 
$color-purple: #B280FF;
$color-yellow:#FFC551; 
$color-green: #62DB8A; 
$color-background: #F3F8FF;
$color-background2: #EBF0F7;

$color-objective-1: rgb(37, 130, 223);
$color-objective-2: rgb(103, 190, 218);
$color-objective-3: rgb(65, 166, 222);
$color-objective-4: rgb(40, 99, 227);

// :export is the magic sauce to be able to use these variables in JS code
// To use these variables you can simply import this file to the js file like 
// `import {colorPrimary} from 'path-to-this-file'`
// usage: `backgroundColor: colorPrimary`
:export {
    colorWhite: $color-white;
    colorOffWhite: $color-off-white;
    colorLight: $color-light;
    colorMedium: $color-medium;
    colorDark: $color-dark;
    colorPrimary: $color-primary;
    colorLightBlue: $color-light-blue;
    colorBlue: $color-blue;
    colorBlueText: $color-blue-text;
    colorDarkBlue: $color-dark-blue;
    colorRed: $color-red;
    colorPurple: $color-purple;
    colorYellow: $color-yellow;
    colorGreen: $color-green;
    colorBackground: $color-background;
    colorObjective1: $color-objective-1;
    colorObjective2: $color-objective-2;
    colorObjective3: $color-objective-3;
    colorObjective4: $color-objective-4;
}