@import "../../../assets/variables.module.scss";

.donut-center{
    position: absolute;
    left: 50%;
    top: 50%;
    text-align: center;
    background: transparent url('../../../assets/srf-text.svg') center center no-repeat;
    background-size: 65%;

    &.donut-center-fr{
        background: transparent url('../../../assets/srf-text-fr.svg') center center no-repeat;
        background-size: 65%;
    }
    &.donut-center-es{
        background: transparent url('../../../assets/srf-text-es.svg') center center no-repeat;
        background-size: 65%;
    }

    h1{
        display: none;
        // font-size: 40px;
        // font-weight: bolder;
        // margin: 15% 0 0;
        // color: $color-primary;
    }

    h2{
        display: none;
        // font-size: 16px;
        // font-weight: normal;
        // color: $color-primary;
        // margin: 0;
    }

    .donut-center-reset{
        position: relative;
        display: inline-block;
        margin-top: 75%;
        padding: 5px 30px 5px 10px;
        background-color: $color-primary;
        color: white;
        cursor: pointer;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        box-shadow: 2px 2px 1px rgba(0, 0, 0, .2);
        border-radius: 8px;

        &:after{
            position: absolute;
            content: '';
            display: block;
            right: 5px;
            top: 0px;
            width: 20px;
            height: 100%;
            background: transparent url('../../../assets/icons/close-white.svg') center center no-repeat;
            background-size: contain;
        }

        @media screen and (max-width: 980px) {
            padding: 2px 20px 2px 5px;
            font-size: 14px;
            &:after{
              right: 2px;
            }
        }
    }

    &.donut-center-fr .donut-center-reset{
      font-size: 14px;
    }

    &.donut-center-en .donut-center-reset{
      margin-top: 80%;
    }
}


// Donut objective title
.srf-objective-title{
  font-size: 13px;
  fill: #fff;
  @media screen and (max-width: 980px) {
    font-size: 12px;
  }
  @media screen and (max-width: 865px) {
    font-size: 10px;
  }
}

.ccp, .oee{
  .srf-objective-title{
    font-size: 18px;
    text-align: center;
  }
}

.ccp{
  .srf-objective-title{
    font-size: 16px;
    text-align: center;
  }
}


// Donut sdg annotations
.donut-annotation-sdgs{
  position: absolute;
  width: 100%;
  bottom: 3px;
  line-height: 22px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap-reverse;
}


.top-left .donut-annotation-sdgs, .top-right .donut-annotation-sdgs{
  bottom: auto;
  top: 3px;
}

.donut-annotation-sdg-icon{
  display: inline-block;
  margin: 0px 5px;
}

.zol-lt-outcome-arrows{
  font-size: 50px;
  fill: $color-blue-text;
  cursor: pointer;
}