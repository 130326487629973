@import "../../assets/variables.module.scss";

.zol-modal-overlay .zol-modal.zol-indicator-details{
    .zol-modal-header{
        background-color: $color-primary;
        color: white;
        .close-icon{ fill: white; }
    }
}

.indicator-path{
    .indicator-path-toggle{
        cursor: pointer;    
        &:before{
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 16px;
            height: 16px;
            border: 1px solid $color-primary;
            border-radius: 50%;
            line-height: 16px;
            font-size: 16px;
            font-weight: bold;
            text-align: center;
            margin-right: 5px;
            background: transparent url('../../assets/icons/fa/plus.svg') center center no-repeat;
            background-size: 10px;
            margin-right: 10px;
        }            
    }
    &.zol-active{
        .indicator-path-toggle:before{
            background-image: url('../../assets/icons/fa/minus.svg');
        }
    }
    span {
        display: block;
        strong{
            display: inline-block;
            min-width: 70px;
        }
        em{
            display: inline-block;
            max-width: calc(100% - 90px);
            vertical-align: top;
        }
    }

    .indicator-path-item{
        padding-left: 50px;
        position: relative;

        >svg {
            position: absolute;
            left: 5px;
            top: 7px;
            fill: $color-medium;
            width: 25px;
            height: 25px;
        }

        &.indicator-path-level2 > svg{ left: 10px; }
        &.indicator-path-level3 > svg{ left: 15px; }
        &.indicator-path-level4 > svg{ left: 20px; }

        em{
            display: block;
            margin-top: 10px;
            font-size: 70%;
            color: $color-medium;
            text-transform: uppercase;
            font-style: none;
        }
        span{
            display: inline-block;
            max-width: calc(100% - 100px);
            vertical-align: top;
        }
    }
}

.indicator-title{
    margin: 30px 0px;
    font-size: 130%;
    padding-left: 50px;
    background: transparent url('../../assets/icons/icon-arrow.svg') 10px 0px no-repeat;
}

.indicator-guidance-box{
    display: inline-block;
    vertical-align: top;
    margin-top: 40px;
    width: calc(50% - 20px);
    &:nth-child(odd){
        margin-right: 20px;
    }
    &:nth-child(even){
        margin-left: 20px;
    }

    h2{
        margin: 0px;
        padding: 10px 20px;
        line-height: 50px;
        font-size: 18px;
        background-color: #C0C0C0;
        > svg{
            display: inline-block;
            vertical-align: middle;
            margin-right: 20px;
            width: 40px;
            height: 40px;
            fill: #666;
        }
    }

    .indicator-guidance-text{
        white-space: pre-wrap;
        background-color: #EAEAEA;
        padding: 10px 15px 30px;
        min-height: 150px;

        h1, h2, h3, h4{
            font-weight: bold;
            color: black;
            margin: 30px 0px 5px;
            font-size: 16px;
        }
    }

    p{
        margin: 5px 0px;
    }

    &.indicator-guidance-notes{
        h2{
            background-color: #C0E1FA;
            > svg{
                fill: #2D9BF0;
            }
        }
        .indicator-guidance-text{
            background-color: #EAF5FD;
        }
        .read-more-box-fade{
            background: linear-gradient(0deg, #EAF5FD 20%, transparent);
        }
    }
    &.indicator-guidance-unit, &.indicator-guidance-reporting_frequency{
        h2{
            background-color: #C3E8D8;
            > svg{
                fill: #37B37F;
            }
        }
        .indicator-guidance-text{
            background-color: #EBF7F2;
        }
        .read-more-box-fade{
            background: linear-gradient(0deg, #EBF7F2 20%, transparent);
        }
    }
    &.indicator-guidance-definition, &.indicator-guidance-extra_indicators{
        h2{
            background-color: #FEE7B2;
            > svg{
                fill: #FBAE00;
            }
        }
        .indicator-guidance-text{
            background-color: #FFF7E5;
        }
        .read-more-box-fade{
            background: linear-gradient(0deg, #FFF7E5 20%, transparent);
        }
    }
    &.indicator-guidance-segregation, &.indicator-guidance-reporting_areas{
        h2{
            background-color: #EDCDB7;
            > svg{
                fill: #F14726;
            }
        }
        .indicator-guidance-text{
            background-color: #FEECE9;
        }
        .read-more-box-fade{
            background: linear-gradient(0deg, #FEECE9 20%, transparent);
        }
    }
    &.indicator-guidance-data_source{
        h2{
            background-color: #B2C0E1;
            > svg{
                fill: #00309A;
            }
        }
        .indicator-guidance-text{
            background-color: #E5EAF5;
        }
        .read-more-box-fade{
            background: linear-gradient(0deg, #E5EAF5 20%, transparent);
        }
    }
    &.indicator-guidance-data_collection{
        h2{
            background-color: #EDF6E8;
            > svg{
                fill: #A9D18E;
            }
        }
        .indicator-guidance-text{
            background-color: #F9FCF7;
        }
        .read-more-box-fade{
            background: linear-gradient(0deg, #F9FCF7 20%, transparent);
        }
    }
    &.indicator-guidance-data_analysis{
        h2{
            background-color: #FCE7DA;
            > svg{
                fill: #F4B183;
            }
        }
        .indicator-guidance-text{
            background-color: #FEF7F2;
        }
        .read-more-box-fade{
            background: linear-gradient(0deg, #FEF7F2 20%, transparent);
        }
    }
}

.zol-indicator-details{

    .indicator-details-buttons{
        margin-top: 30px;
    }

}

.read-more-box{
    position: relative;

    .read-more-box-fade{
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        height: 100px;
        background: linear-gradient(0deg, #EAEAEA 20%, transparent);
    }

    > button{
        width: 100%;
        background: transparent;
        border: 0px;
        color: $color-blue;
        cursor: pointer;
        text-decoration: underline;
        font-family: "Open Sans", Arial, sans-serif;
        font-size: 100%;
    }

    &.read-more-box-closed > button{
        position: absolute;
        bottom: 10px;
        width: 100%;
    }

    a{
        color: $color-blue;
    }
}