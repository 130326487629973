@import "../../assets/variables.module";

.zol-st-sdg, .zol-st-gcm{
    text-align: left;
    margin: 0;
    padding: 0px 40px;

    @media screen and (max-width: 980px){
        padding: 0px 10px;
    }

    h3{
        font-weight: bold;
        font-size: 100%;
        margin: 0;
        padding-top: 10px;
    }

    ul{
        margin: 0px;
        padding: 0px;
        list-style: none;

        li{
            padding: 10px 0px;
            svg{
                width: 45px;
                height: 45px;
                float: left;
                margin-top: 5px;
            }

            div{
                padding-left: 60px;
                .zol-st-sdg-num{
                    font-weight: bold;
                    margin-right: 5px;
                }
            }

            &:after{
                content: '';
                display: block;
                clear: both;
            }
            
        }
    }
}

.zol-st-gcm ul li{
    svg{
        margin-top: 0px;
        width: 70px;
        height: 70px;
    }
    div{
        padding-left: 85px;
    }
}