.zol-introduction-enabled{
  pointer-events: none;
}
.zol-introduction{
  position: relative;
  .zol-introduction-modal{
    position: fixed;
    width: 250px;
    height: 140px;
    left: calc(50% - 125px);
    top: calc(50% - 70px);
    z-index: 10;
    background: #fff;
    border: 0.5px solid #808080;
    border-radius: 25px;
    padding: 25px;
    text-align: center;
    h3{
      margin: 0 0 10px;
    }
    .modal-buttons{
      display: flex;
      justify-content: space-between;
    }
  }
  .zol-introduction-button{
      float: left;
      line-height: 30px;
      padding: 0px 10px 0px 6px;
      margin-top: 8px;
      border-radius: 5px;
      margin-left: 10px;
      > svg{
        vertical-align: middle;
        width: 16px;
        height: 16px;
        margin: -2px 5px 0px 0px;
      }
      color: #fff;
      font-size: 13px;
      font-style: italic;
      background-color: #5b77ba;
      cursor: pointer;
      &:hover{
          background-color: rgba(255, 255, 255, 0.2);
      }
  }
}
