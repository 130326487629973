@import "../../../assets/variables.module.scss";

.zol-table-scrollable{
    overflow-x: auto;
}
.zol-table{
    position: relative;
    width: 100%;
    border-collapse: collapse;

    .zol-table-header{        
        .zol-table-header-item{
            text-align: left;
            color: $color-dark;
            font-weight: bold;
            padding-bottom: 20px;
            padding-top: 20px;
            margin-right: 20px;
            vertical-align: bottom;

            &:first-child{
                padding-left: 15px;
            }
            &:last-child{
                padding-right: 15px;
            }

            .zol-table-header-sort{
                display: inline-flex;
                flex-direction: column;
                margin-left: 5px;
                vertical-align: middle;

                > span > svg{
                    width: 15px;
                    height: 15px;
                }
            }

        }
        .zol-table-header-item-rotated{
            height: 100px;

            .zol-table-header-item-label{
                display: inline-block;
                /* TODO: positioning the rotated header at the correct position automatically is tricky, so
                    for now this is a manual trial and error approach. Lets find a better approach */
                transform: translate(35px, -80px) rotate(-35deg);
                text-align: left;
                overflow: hidden;
                word-break: break-word;
                text-overflow: ellipsis;
                position: absolute;
                max-height: 44px;
                width: 175px;
            }
        }
    }

    .zol-table-body{
        .zol-table-row{
            &:nth-child(odd){
                .zol-table-cell{
                    background-color: $color-off-white;
                }
            }
            .zol-table-cell{
                text-align: left;
                padding: 5px 10px 5px 0;
                &:first-child{
                    padding-left: 15px;
                }
                &:last-child{
                    padding-right: 15px;
                }
            }
        }
    }

}
.zol-table-navigation{
    height: 60px;
}