@import "../../../assets/variables.module.scss"; 

#zol-header #zol-header-row-2 .zol-header-filter-bar{

    .zol-select-grouped{
        padding: 0px 5px 0px 10px;
        background-color: #5b77ba;
        width: 235px;
        height: 45px;
    
        .zol-select-grouped-label{
            height: 45px;
            line-height: 45px;
        }
    
        .zol-select-grouped-toggle{
            height: 100%;
        }

        @media screen and (max-width: 1060px) {
            width: 200px;
        }
    
    }

    &.zol-mobile-filters{
        float: none;
        width: calc(100% - 40px);
        padding: 20px;
        height: auto;
        background-color: transparent;
        border: 1px solid #ddd;
        border-width: 1px 0px;

        .zol-select-grouped{
            display: block;
            padding: 0px 5px 0px 10px;
            background-color: white;
            width: calc(100% - 15px);
            border: 1px solid #aaa;
            border-radius: 15px;
            margin: 10px 0px;

            .zol-select-grouped-toggle{
                background-image: url('../../../assets/icons/fa/angle-down-dark.svg');
            }
        
            &.zol-has-value .zol-select-grouped-toggle{
                background-image: url('../../../assets//icons/fa/close-dark.svg');
            }
        
            &.zol-active .zol-select-grouped-toggle{
                background-image: url('../../../assets/icons/fa/angle-down-dark.svg');
            }
        }
    }

}

.zol-select-grouped-dropdown, .zol-select-grouped-modal{   
    > .zol-select-grouped-search{
        top: -45px;
        height: 45px;
        > input{
            height: 45px;
            line-height: 45px;
            padding: 0px 5px;
            background-color: #5b77ba;
            color: white;
            font-size: 16px;

            &::placeholder{
                color: rgba(255, 255, 255, 0.5);
                font-style: italic;
            }
        }
    }

    > ul{
        width: calc(100% + 65px);
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
    }

    .zol-level-0 li.zol-select-grouped-option{
        padding: 10px 0px;

        &:hover{
            background-color: $color-background;
        }

        .zol-select-grouped-option-label{
            font-weight: bold;
        }
    }

    ul.zol-level-1{
        margin: 10px 0px -10px 0px;
        background-color: #f5f5f5;
    }

    .zol-level-1 li.zol-select-grouped-option{
        padding: 5px 0px;

        .zol-select-grouped-option-label{
            font-weight: normal;
        }

        &:hover{
            background-color: #f9f9f9;
        }
    }

    li.zol-select-grouped-option{

        .zol-select-grouped-checkbox{
            float: left;
            width: 16px;
            height: 16px;
            border: 1px solid #ddd;
            border-radius: 2px;
        
            &.zol-checkbox-checked{
                border: 0px;
                background-image: url('../../../assets/icons/fa/check-square.svg');
                width: 18px;
                height: 18px;
                background-size: 18px;
            }

            &.zol-checkbox-partly:before{
                background-color: $color-primary;
            }
        }

        .zol-select-grouped-group-toggle{
            width: 18px;
            height: 18px;
            border: 1px solid $color-primary;
            line-height: 18px;
            background-color: #e6ecff;

            &:hover{
                background-color: #b0bff1;
            }
        }
    }

    p.zol-no-data{
        padding: 0px 20px;
        font-style: italic;
    }
}


.filter-sdg-container, .filter-gcm-container{
    margin: 0px 2px;
    padding-bottom: 90px;
    background: $color-background2 url('../../../assets/icons/sdg_vector.svg') center 45px no-repeat;
    background-size: 60%;
    border-radius: 0px 0px 30px 30px;
}
.filter-gcm-container{
    background-image: url('../../../assets/icons/gcm_vector.svg');
    background-size: 75%;
}

.zol-mobile-filters{
    .filter-sdg-container, .filter-gcm-container{
        margin: 0px;
        padding: 0px;
        background: transparent;
        border-radius: 0px;
        position: relative;

        &:after{
            content: '';
            position: absolute;
            top: 6px;
            left: 4px;
            width: 36px;
            height: 36px;
            background: transparent url('../../../assets/icons/sdg_small_logo.svg') center center no-repeat;
            background-size: 80%
        }

        .zol-select-grouped-label{
            text-indent: 35px;
        }
    }
    .filter-gcm-container:after{
        background-image: url('../../../assets/icons/gcm_small_logo.svg');
    }
}

.filter-sdg-goal-option{
    display: inline-block;
    width: calc(100% - 30px);
    font-weight: normal;
}

@media screen and (max-width: 1000px) {
    .filter-sdg-goal-option{
        width: calc(100% - 70px);
    }
}