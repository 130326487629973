@import '../../assets/variables.module.scss';

.search-bar-container{
    border: 1px solid $color-medium;
    display: flex;
    margin-right: 20px;
    background-color: $color-background;
    overflow: hidden;
    width: 100%;
    height: 40px;
    border-radius: 20px;

    &.zol-active{
        border-radius: 20px 20px 0px 0px;
    }

    .zol-single-input {
        width: calc(100% - 90px);
        font-family: "Open Sans", Arial, sans-serif;
        display: inline-block;
        margin: 0;
        border: none;
        background-color: $color-background;
        outline: none;
        font-size: 14px;
        padding: 0px 10px 0px 20px;
    }
    
    .search-button{
        width: 50px;
        background-color: $color-background;
        margin: auto;
        padding-right: 10px;
        cursor: pointer;
        text-align: center;
    }
    
    .cancel-button{
        @extend .search-button;
        color: #fff;
        font-size: 18px;
    }
    
    .search-icon{
        color: $color-medium;
    }
}