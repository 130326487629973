@import "../../../assets/variables.module.scss";

.zol-ui-button{
    display: inline-block;
    padding: 8px 20px;
    cursor: pointer;
    background-color: $color-blue;
    color: white;
    border-radius: 9px;
}
.zol-ui-button-disabled{
    opacity: .6;
    cursor: not-allowed;
}
.zol-ui-button.zol-light{
    background-color: $color-light;
    color: white;
}

.zol-ui-button-card{
    background-color: rgba(255,255,255,0.7);
    color: black;
    padding: 15px 20px 20px;
    transition: background-color 0.2s;

    span{
        display: inline-block;
        vertical-align: middle;
        width: calc(100% - 55px);
        line-height: 1.2em;
        margin: -3px 0px 0px;
        font-size: 120%;

        svg{
            width: 100%;
            height: 100%;
        }
    }
    span:first-child{
        width: 35px;
        height: 35px;
        margin: 3px 20px 0px 0px;
        transition: width .2s, height .2s, margin .2s;
    }

}
.zol-ui-button-card:hover{
    background-color: rgba(255,255,255,0.8);
    span:first-child{
        width: 41px;
        height: 41px;
        margin: 0px 17px 0px -3px;
    }
}

.zol-ui-button-icon{

    svg{
        width: 25px;
        height: 25px;
        fill: white;
        vertical-align: middle;
        margin: 0px 15px 0px -5px;
    }
}

.zol-blue{
    display: inline-block;
    background-color: $color-blue-text;
    color: $color-white;
    border-radius: 0;
    margin: 20px 0 10px;
}