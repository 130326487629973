@import "../assets/variables.module.scss";

.zol-main{
  position: relative;
  min-height: 100vh;
  padding: 110px 20px;
  background-color: $color-background;
  @media screen and (max-width: 980px){
    padding: 20px 0 10px;
  }

  &.saved{
    padding-top: 150px;
  }
}

.gradient > div{
    position: relative;
    margin: 0 auto;
    width: calc(100% - 1200px);
}

@media screen and (max-width: 1400px) {
    .gradient > div{
        margin: 0px;
        width: calc(100% - 600px);
    }
}

@media screen and (max-width: 1020px) {
    .gradient{ display: none; }
}

.zol-modal-content{
    overflow: auto;
}

.zol-header-filter-bar{
    .zol-tree{
        .zol-tree-parent{
            color: $color-blue;
        }
        .zol-tree-node .zol-tree-node-toggle{
            color: $color-blue;
        }
        .tree-header-container{
            margin-right: 5px;
        }
        ul{
            padding-left: 10px;
        }
    }
}

.srf-sign-in{
    margin: 40px auto;
    max-width: 700px;
    background-color: white;
    padding: 20px;
}

.page-switch{
    text-align: center;

    .zol-ui-switch, .zol-ui-switch-vertical{
        overflow: visible;
        transition: opacity 0.5s
    }

    .zol-ui-switch-item:nth-child(2){
        border-radius: 10px 0px 0px 10px;
        background-color: rgba(91, 146, 229, 0.6);
        &.zol-active{
            background-color: rgb(91, 146, 229);
        }
    }
    .zol-ui-switch-item:nth-child(3){
        background-color: rgba(16, 50, 156, 0.6);
        &.zol-active{
            background-color: rgb(16, 50, 156);
        }
    }
    .zol-ui-switch-item:nth-child(4){
        border-radius: 0px 10px 10px 0px;
        background-color: rgba(92, 184, 178, 0.6);
        &.zol-active{
            background-color: rgb(92, 184, 178);
        }
    }

    .zol-ui-switch-item > span{
        display: inline-block;
        width: 130px;
        padding: 0px 0px;
        vertical-align: top;
        color: white;

        strong {
            text-transform: uppercase;
        }

        strong, em{
            display: block;
        }
        em{
            font-style: normal;
            font-size: 90%;
        }
    }

    .zol-ui-switch-highlight{
        background-color: transparent;
    }

    .zol-ui-switch-highlight:after{
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    .zol-ui-switch-item{
        line-height: 80px;
        padding: 10px 0px;

        >span{
            vertical-align: middle;
            line-height: 1em;
        }
    }

    &.page-switch-desktop{
        position: absolute;
        left: 30px;
        top: calc(50vh - 90px);

        .zol-ui-switch-highlight:after{
            left: 100%;
            top: 50%;
            border: transparent solid;
            border-left-color: $color-primary;
            border-width: 15px;
            margin-top: -15px;
        }

        &.page-type-ccp .zol-ui-switch-highlight{
            &:after{
                border-left-color: rgb(91, 146, 229);
            }
        }
    
        &.page-type-oee .zol-ui-switch-highlight{
            &:after{
                border-left-color: rgb(92, 184, 178);
            }
        }

        .zol-ui-switch-item:nth-child(2){
            border-radius: 20px 20px 0px 0px;
            
        }
        .zol-ui-switch-item:nth-child(4){
            border-radius: 0px 0px 20px 20px;
        }
    }

    &.page-switch-mobile{
        .zol-ui-switch-highlight:after{
            top: 100%;
            left: 50%;
            border: solid transparent;
            border-top-color: $color-primary;
            border-width: 15px;
            margin-left: -15px;
        }

        .zol-ui-switch-item{
            line-height: 70px;

            >span {
                width: 115px;

                > em{
                    line-height: 1.5em;
                }
            }
        }

        &.page-type-ccp .zol-ui-switch-highlight{
            &:after{
                border-top-color: rgb(91, 146, 229);
            }
        }
    
        &.page-type-oee .zol-ui-switch-highlight{
            &:after{
                border-top-color: rgb(92, 184, 178);
            }
        }
    }
}
