@import "../../assets/variables.module.scss";

.zol-indicator-line{
    position: relative;
    .zol-indicator-title{
        max-width: 200px;
        color: #545454;
        position: absolute;
        top: -18px;
        left: 0;
        right: 0;
        margin: auto;
        background-color: #fff;
        border-radius: 15px;
        padding: 8px 15px;
        z-index: 1;
        font-weight: bold;
        text-transform: uppercase;
        .zol-indicator-mobile-nav{
            .mobile-nav-circle{
                background-color: $color-light;
                width: 10px;
                height: 10px;
                margin: 0 5px;
                display: inline-block;
                border-radius: 10px;
            }
        }
    }
    .zol-box-horizontal-line{
        margin: auto;
        position: relative;
        .zol-indicator-line-horizontal{
            border-top: 1px solid $color-blue-text;
            position: absolute;
            width: 100%;
            margin: auto;
            left: 0;
            right: 0;
        }
    }
    .zol-box-line{
        height: 75px;
        .zol-line{
            display: inline-block;
            width: 1px;
            height: 100%;
            margin-bottom: -5px;
            background-color: $color-blue-text;
            position: relative;
        }
    }
}

.zol-item-component{
    width: calc(100% - 20px);
    //display: inline-block;
    margin: 0 5px;
    vertical-align: top;
    // create rounded edges at the bottom of the last child
    :last-child{
        border-radius: 0px 0px 10px 10px;
    }
    position: relative;
    z-index: 1;
    .zol-item-block{
        background-color: $color-off-white;
        .block-text{
            color: black;
            float: left;
            width: calc(100% - 20px);
            background-color: white;
            padding: 10px;
        }
        .block-title{
            position: relative;
            text-transform: uppercase;
            width: 100%;
            padding: 10px 0;
            background-color: $color-light-blue;
            color: white;
            font-weight: bold;
            float: left;
            // create rounded edges at the top of the title bar
            border-radius: 10px 10px 0px 0px;

        }

        .block-expandable > span:first-child{
            /* because the caret pushes the span a bit to the left. Adding 50px padding ensured the text is
               centered */
            padding-left: 50px;
        }
    }
    &:before {
        border-left: 1px solid $color-blue-text;
        content:"";
        margin: 0 auto; /* this centers the line to the full width specified */
        position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
        top: 0; left: 50%; right: 0; bottom: 0;
        height: 100%;
        z-index: -1;
    }
}


.zol-short-term-overview-mobile-item{
    width: calc(100% - 20px);
    margin: 0 10px 40px 10px;
    .block-title-box{
        background-color: white;
        z-index: 1;
        overflow: hidden;
        padding: 0px 5px;
        border-radius: 20px 20px 0px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        .block-title{
            float: left;
            width: calc(100% / 3 * 2 - 20px);
            text-align: left;
            padding: 10px 0 0 5px;
            >span{
                font-weight: bold;
                text-transform: uppercase;
            }
            >p{
                color: black;
                float: left;
                width: 100%;
            }
        }
        .details-button{
            float:left;
            width: calc(100% / 3);
        }
    }
}

.st-mobile-details-menu{
    background-color: #EBF0F7;
    width: 100%;
    overflow: hidden;
    border-radius: 0px 0px 20px 20px;
    display: flex;
    .st-mobile-details-menu-item{
        cursor: pointer;
        width: 25%;
        padding: 10px 0;
        &:hover{
            border-radius: 20px 20px 0px 0px;
            color: white;
            background-color: rgba(37, 130, 223, .25);
        }
        .st-mobile-details-menu-item-count{
            font-weight: bold;
            font-size: 22px;
            display: block;
        }
        .st-mobile-details-menu-item-title{
            width: calc(100% - 10px);
            margin: 0 5px;
            font-size: 14px;
            display: block;
        }
    }
}
.st-mobile-details-list{
    background-color: white;
    border-radius: 10px;
    padding: 10px;
}

.zol-indicator-line .zol-indicator-title.zol-link{
    text-decoration: none;
    color: $color-blue;
}