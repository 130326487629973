@import "../../../assets/variables.module";

// select element style
.zol-single-select{
    display: block;
    width: 100%;
    outline: none;
    border: none;
    padding: 10px;
    background-color: $color-off-white;
    margin: 10px 0px 5px 0px;
}

textarea.zol-single-input {
    font-family: "Lato", sans-serif;
    min-height: 60px;
}