@import "../assets/variables.module.scss";

.zol-details-page{
  text-align: center;
  max-width: 1400px;
  margin: 0 auto;
  padding-top: 10px;

  h2{
    margin: 0;
  }
  h3{
    font-weight: 400;
    margin: 5px 0;
  }

  .details-short-term-count{
    font-weight: 400;
    text-decoration: underline;
    color: $color-blue-text;
    cursor: pointer;
    margin: 5px 0;
  }
  .zol-srf-export{
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
.gradient{
  z-index: 2;
  font-style: italic;
  font-size: 90%;
  text-align: center;
  padding: 15px 0;
  width: 100%;
  height: 60px;
  position: fixed;
  top: 110px;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,0.9) 50%,rgba(255,255,255,0) 100%);
  pointer-events: none;

  .zol-link{
    margin-left: 5px;
    font-weight: bold;
  }

  @media screen and (max-width: 980px){
    top: 126px;    
  }
}

.donut-center a.link-strategic-plan{
  position: absolute;
  bottom: 15px;
  width: 100%;
  left: 0px;
  font-size: 80%;

  &:visited{
    color: $color-blue-text;
  }

  > svg{
    width: 10px;
    fill: $color-blue-text;
  }
}